import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Logo from './logo.png';
import './tree.css';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.openHome = this.openHome.bind(this);
  }

  getTreeData() {
    var data = [
      {
        "title": "O que é o COVID-19?",
        "duration": "2 a 3 minutos",
        "url": "lessons/qhxWi8IlHHgsGddpZxg_lLPp1DMja5Xz"
      },
      {
        "title": "Como ocorre o contágio?",
        "duration": "5 a 6 minutos",
        "url": "lessons/QROpvLFV1o7ftFuTCt9sCb7CFY7bu-79"
      },
      {
        "title": "Sintomas, diagnóstico e tratamento",
        "duration": "2 a 3 minutos",
        "url": "lessons/mVf4EFHchyBiliIIDH6HPE1dok1Kx3xT"
      },
      {
        "title": "Prevenção",
        "duration": "8 a 9 minutos",
        "url": "lessons/87itfpZF3GGdGCsiqA-AtFr3j5PYpDGs"
      },
      {
        "title": "O que fazer caso me sinta doente?",
        "duration": "2 a 3 minutos",
        "url": "lessons/SdmklzCthqcVepgRJqZsXvvtRDr2vjdJ"
      },
      {
        "title": "Como me preparar para uma pandemia?",
        "duration": "5 a 6 minutos",
        "url": "lessons/mM4D9udqYGDsMyQniu4wYipgBu4HxKtw"
      },
      {
        "title": "FAQ",
        "duration": "5 a 6 minutos",
        "url": "lessons/f1Ex5Fg5Tu014nq4KWB1EHwONnMyqx9c"
      },
      {
        "title": "Referências",
        "duration": "3 a 4 minutos",
        "url": "lessons/xTzX9gxBE9VU-8c7Utm3AMsAdsuouZfn"
      }
    ]
    return data;
  }

  openHome() {
    window.location = window.location.origin + "/#/";
  }

  getCustomLogo() {
    if (this.props.customLogo !== undefined) {
      return <img className="rdp-tree-header-logo-custom" src={`clients/${this.props.customLogo}.png`}/>; 
    };
  }

  render() {
    var _this = this;
    var items = this.getTreeData();
    return (
      <div className="rdp-tree">
        <div className="rdp-tree-header">
          <img className="rdp-tree-header-logo" src={Logo} onClick={() => this.openHome()}/>
          {this.getCustomLogo()}
          <span className="rdp-tree-header-close" onClick={() => this.props.onCloseClick()}></span>
        </div>
        <ul className="rdp-tree-items">
          {items.map(function(item, index) {
            return (<li key={index} onClick={() => _this.props.onPathChange(item.url)}>
              <span className="rdp-tree-bullet">{index + 1}</span>
              <span className="rdp-tree-title">{item.title}</span>
              <span className="rdp-tree-duration">{item.duration}</span>
              <span className="rdp-tree-play"></span>
            </li>);
          })}
        </ul>
      </div>
    );
  }
}
