import React, {Component} from 'react';
import platform from 'platform';
import $ from 'jquery';
import './home.css';

import {
  Header,
  Tree,
} from '../../components';

export default class ViewHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "opened_tree": false,
      "pathname": ""
    }
  }

  componentDidMount() {
    this.sendEvent();
  }

  getUUIDV4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getUserAgent() {
    var product = platform.product !== null ? platform.product : "Desktop";
    return "rdpcovid/1.0 (" + product + "; " + platform.os.family + "; " + platform.os.version + ") Framework/1.0 Config/1.0";
  }

  sendEvent() {
    var _this = this;
    $.ajax({
      type: "POST",
      url: "https://api.digitalpages.com.br/util/v2/ping",
      headers: {
        "Project-Key": "b57c474ff54c4e33984e668fe13c3ae1",
        "Agent-Uid": _this.getUserAgent(),
        "Session-Uid": _this.getUUIDV4(),
        "Content-Type": "application/json",
        "Cache-Control": "no-cache"
      }
    }).done(function(data) {
      console.log(data);
    }).fail(function() {
      console.log("error");
    });
  }

  openLink(link) {
    window.location = window.location.href + link;
  }

  getTree() {
    if (this.state.opened_tree === true) {
      return <Tree
        customLogo={this.props.match.params.client}
        onCloseClick={() => this.closeTree()}
        onPathChange={(pathname) => this.changePathname(pathname)}
      />
    }
  }

  openTree() {
    this.setState({
      "opened_tree": true
    });
  }

  closeTree() {
    this.setState({
      "opened_tree": false
    });
  }

  changePathname(pathname) {
    this.setState({
      "opened_tree": false,
      "pathname": pathname
    })
  }

  openDpSite() {
    window.open("https://www.digitalpages.com.br", "_blank");
  }

  render() {
    return (<div className="rdp-covid-view-home">
      <Header customLogo={this.props.match.params.client} onMenuClick={() => this.openTree()}/>
      <div className="rdp-covid-content">
        <iframe className="rdp-covid-content-iframe" src={`content/index.html#/${this.state.pathname}`}/>
        {this.getTree()}
        <div className="rdp-covid-rdp-learning" onClick={() => this.openDpSite()}></div>
      </div>
    </div>);
  }
}
