import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Logo from './logo.png';
import './header.css';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.openHome = this.openHome.bind(this);
  }
  
  openHome() {
    window.location = window.location.origin + "/#/";
  }

  getCustomLogo() {
    if (this.props.customLogo !== undefined) {
      return <img className="rdp-html-builder-component-header-logo-custom" src={`clients/${this.props.customLogo}.png`}/>; 
    };
  }

  render() {
    return (
      <div className="rdp-html-builder-component-header">
        <img className="rdp-html-builder-component-header-logo" src={Logo} onClick={() => this.openHome()}/>
        {this.getCustomLogo()}
        <span className="rdp-header-menu" onClick={() => this.props.onMenuClick()}></span>
      </div>
    );
  }
}
